:root, :host {
  --fa-font-solid: normal 900 1em/1 'Font Awesome 6 Solid';
  --fa-font-regular: normal 400 1em/1 'Font Awesome 6 Regular';
  --fa-font-light: normal 300 1em/1 'Font Awesome 6 Light';
  --fa-font-thin: normal 100 1em/1 'Font Awesome 6 Thin';
  --fa-font-duotone: normal 900 1em/1 'Font Awesome 6 Duotone';
  --fa-font-sharp-solid: normal 900 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-regular: normal 400 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-light: normal 300 1em/1 'Font Awesome 6 Sharp';
  --fa-font-sharp-thin: normal 100 1em/1 'Font Awesome 6 Sharp';
  --fa-font-brands: normal 400 1em/1 'Font Awesome 6 Brands'; }

svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box; }

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -.125em; }
  .svg-inline--fa.fa-2xs {
    vertical-align: 0.1em; }
  .svg-inline--fa.fa-xs {
    vertical-align: 0em; }
  .svg-inline--fa.fa-sm {
    vertical-align: -0.07143em; }
  .svg-inline--fa.fa-lg {
    vertical-align: -0.2em; }
  .svg-inline--fa.fa-xl {
    vertical-align: -0.25em; }
  .svg-inline--fa.fa-2xl {
    vertical-align: -0.3125em; }
  .svg-inline--fa.fa-pull-left {
    margin-right: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-pull-right {
    margin-left: var(--fa-pull-margin, 0.3em);
    width: auto; }
  .svg-inline--fa.fa-li {
    width: var(--fa-li-width, 2em);
    top: 0.25em; }
  .svg-inline--fa.fa-fw {
    width: var(--fa-fw-width, 1.25em); }

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0; }

.fa-layers-text, .fa-layers-counter {
  display: inline-block;
  position: absolute;
  text-align: center; }

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -.125em;
  width: 1em; }
  .fa-layers svg.svg-inline--fa {
    transform-origin: center center; }

.fa-layers-text {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center; }

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: var(--fa-counter-padding, 0.25em 0.5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  transform: scale(var(--fa-counter-scale, 0.25));
  transform-origin: top right; }

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  top: auto;
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: bottom right; }

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: bottom left; }

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: top right; }

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  transform: scale(var(--fa-layers-scale, 0.25));
  transform-origin: top left; }

.fa-1x {
  font-size: 1em; }

.fa-2x {
  font-size: 2em; }

.fa-3x {
  font-size: 3em; }

.fa-4x {
  font-size: 4em; }

.fa-5x {
  font-size: 5em; }

.fa-6x {
  font-size: 6em; }

.fa-7x {
  font-size: 7em; }

.fa-8x {
  font-size: 8em; }

.fa-9x {
  font-size: 9em; }

.fa-10x {
  font-size: 10em; }

.fa-2xs {
  font-size: 0.625em;
  line-height: 0.1em;
  vertical-align: 0.225em; }

.fa-xs {
  font-size: 0.75em;
  line-height: 0.08333em;
  vertical-align: 0.125em; }

.fa-sm {
  font-size: 0.875em;
  line-height: 0.07143em;
  vertical-align: 0.05357em; }

.fa-lg {
  font-size: 1.25em;
  line-height: 0.05em;
  vertical-align: -0.075em; }

.fa-xl {
  font-size: 1.5em;
  line-height: 0.04167em;
  vertical-align: -0.125em; }

.fa-2xl {
  font-size: 2em;
  line-height: 0.03125em;
  vertical-align: -0.1875em; }

.fa-fw {
  text-align: center;
  width: 1.25em; }

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0; }
  .fa-ul > li {
    position: relative; }

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit; }

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, 0.1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, 0.08em);
  padding: var(--fa-border-padding, 0.2em 0.25em 0.15em); }

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, 0.3em); }

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, 0.3em); }

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.28, 0.84, 0.42, 1)); }

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(0.4, 0, 0.6, 1)); }

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out); }

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear); }

.fa-spin-reverse {
  --fa-animation-direction: reverse; }

.fa-pulse,
.fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8)); }

@media (prefers-reduced-motion: reduce) {
  .fa-beat,
  .fa-bounce,
  .fa-fade,
  .fa-beat-fade,
  .fa-flip,
  .fa-pulse,
  .fa-shake,
  .fa-spin,
  .fa-spin-pulse {
    animation-delay: -1ms;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s; } }

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1); }
  45% {
    transform: scale(var(--fa-beat-scale, 1.25)); } }

@keyframes fa-bounce {
  0% {
    transform: scale(1, 1) translateY(0); }
  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, 0.9)) translateY(0); }
  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, 0.9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -0.5em)); }
  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, 0.95)) translateY(0); }
  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -0.125em)); }
  64% {
    transform: scale(1, 1) translateY(0); }
  100% {
    transform: scale(1, 1) translateY(0); } }

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, 0.4); } }

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, 0.4);
    transform: scale(1); }
  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125)); } }

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg)); } }

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg); }
  4% {
    transform: rotate(15deg); }
  8%, 24% {
    transform: rotate(-18deg); }
  12%, 28% {
    transform: rotate(18deg); }
  16% {
    transform: rotate(-22deg); }
  20% {
    transform: rotate(22deg); }
  32% {
    transform: rotate(-12deg); }
  36% {
    transform: rotate(12deg); }
  40%, 100% {
    transform: rotate(0deg); } }

@keyframes fa-spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.fa-rotate-90 {
  transform: rotate(90deg); }

.fa-rotate-180 {
  transform: rotate(180deg); }

.fa-rotate-270 {
  transform: rotate(270deg); }

.fa-flip-horizontal {
  transform: scale(-1, 1); }

.fa-flip-vertical {
  transform: scale(1, -1); }

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1, -1); }

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0)); }

.fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em; }

.fa-stack-1x,
.fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--fa-stack-z-index, auto); }

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em; }

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em; }

.fa-inverse {
  color: var(--fa-inverse, #fff); }

.sr-only,
.fa-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.sr-only-focusable:not(:focus),
.fa-sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0; }

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, 0.4); }

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1); }

.svg-inline--fa mask .fa-primary,
.svg-inline--fa mask .fa-secondary {
  fill: black; }

.fad.fa-inverse,
.fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff); }

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}.__variable_d65c78 {--font-inter: '__Inter_d65c78', '__Inter_Fallback_d65c78'
}

@font-face {
font-family: '__helveticaNeue_4f4820';
src: url(/_next/static/media/e2542b709b225903-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__helveticaNeue_4f4820';
src: url(/_next/static/media/e1689dbe909aa6ef-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__helveticaNeue_4f4820';
src: url(/_next/static/media/70b75ad00960e9db-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__helveticaNeue_Fallback_4f4820';src: local("Arial");ascent-override: 70.43%;descent-override: 19.53%;line-gap-override: 8.19%;size-adjust: 101.37%
}.__className_4f4820 {font-family: '__helveticaNeue_4f4820', '__helveticaNeue_Fallback_4f4820'
}.__variable_4f4820 {--font-helvetica-neue: '__helveticaNeue_4f4820', '__helveticaNeue_Fallback_4f4820'
}

.react-autosuggest__container {
  width: 100%;
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  padding: 8px 10px 8px 35px !important;
  border-radius: 4px !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  background-color: #ffffff;
  z-index: 100;
  box-shadow: 0px 4px 10px 0px #00000033;
  position: fixed;
  margin-top: 81px;
  overflow-y: auto;
  border-radius: 0.125rem;
  font-family: var(--font-helvetica-neue), Helvetica, Arial, sans-serif;
}

@media not all and (min-width: 1536px) {

  .react-autosuggest__suggestions-container--open {
    left: 0px;
    bottom: 0px;
    top: 0px;
    width: 100vw;
  }
}

@media (min-width: 1536px) {

  .react-autosuggest__suggestions-container--open {
    position: absolute;
    right: 0px;
    margin-top: 0.5rem;
    width: 100%;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestions-list > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  --tw-divide-opacity: 1;
  border-color: rgb(232 231 230 / var(--tw-divide-opacity));
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.react-autosuggest__suggestion--highlighted {
  --tw-bg-opacity: 1;
  background-color: rgb(240 247 247 / var(--tw-bg-opacity));
}

.react-autosuggest__section-container {
  border-top-width: 1px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(90 155 163 / var(--tw-border-opacity));
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  --tw-bg-opacity: 1;
  background-color: rgb(240 247 247 / var(--tw-bg-opacity));
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(25 77 94 / var(--tw-text-opacity));
}

.react-autosuggest__section-container--first .react-autosuggest__section-title:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.rc-slider {
  position: relative;
  width: 100%;
  height: 24px;
  padding: 5px 0;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  height: 8px;
  background-color: #9ca3af;
  border-radius: 8px;
}
.rc-slider-track {
  position: absolute;
  height: 8px;
  background-color: #193b59;
  border-radius: 8px;
}
.rc-slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -6px;
  background-color: #ffffff;
  border: solid 2px #193b59;
  border-radius: 50%;
  cursor: pointer;
  cursor: grab;
  opacity: 0.8;
  touch-action: pan-x;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #193b59;
  box-shadow: 0 0 0 5px #193b59;
}
.rc-slider-handle:focus {
  outline: none;
  box-shadow: none;
}
.rc-slider-handle:focus-visible {
  border-color: #193b59;
  box-shadow: 0 0 0 3px #193b59;
}
.rc-slider-handle-click-focused:focus {
  border-color: #193b59;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #193b59;
}
.rc-slider-handle:active {
  border-color: #193b59;
  box-shadow: 0 0 5px #193b59;
  cursor: grabbing;
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: #999;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
  pointer-events: none;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  background-color: #fff;
  border: 2px solid #e9e9e9;
  border-radius: 50%;
  cursor: pointer;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  background-color: #fff;
  border-color: #ccc;
  box-shadow: none;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-mark-text,
.rc-slider-disabled .rc-slider-dot {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-track {
  bottom: 0;
  left: 5px;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-top: 0;
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  width: 4px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-dot {
  margin-left: -2px;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  display: block !important;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-leave {
  display: block !important;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: scale(0, 0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    transform: scale(0, 0);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform: scale(1, 1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0, 0);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.rc-slider-tooltip {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: visible;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px 0;
}
.rc-slider-tooltip-inner {
  min-width: 24px;
  height: 24px;
  padding: 6px 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}

/* -------------------------------------------------------------------------- */

._slider-blog-categories .slick-track {
  display: flex;
  align-items: center;
}

.slider-blog-categories .slick-slide {
  text-align: center;
}

.slider-blog-categories .slick-slide a span {
  vertical-align: middle;
}

.ais-Breadcrumb-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list,
.ais-Hits-list,
.ais-Results-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-Pagination-list,
.ais-RatingMenu-list,
.ais-RefinementList-list,
.ais-ToggleRefinement-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.ais-ClearRefinements-button,
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteHits-loadMore,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RangeInput-submit,
.ais-RefinementList-showMore,
.ais-SearchBox-submit,
.ais-SearchBox-reset,
.ais-VoiceSearch-button {
  padding: 0;
  overflow: visible;
  font: inherit;
  line-height: normal;
  color: inherit;
  background: none;
  border: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }
  .ais-ClearRefinements-button::-moz-focus-inner,
  .ais-CurrentRefinements-delete::-moz-focus-inner,
  .ais-CurrentRefinements-reset::-moz-focus-inner,
  .ais-GeoSearch-redo::-moz-focus-inner,
  .ais-GeoSearch-reset::-moz-focus-inner,
  .ais-HierarchicalMenu-showMore::-moz-focus-inner,
  .ais-InfiniteHits-loadPrevious::-moz-focus-inner,
  .ais-InfiniteHits-loadMore::-moz-focus-inner,
  .ais-InfiniteResults-loadMore::-moz-focus-inner,
  .ais-Menu-showMore::-moz-focus-inner,
  .ais-RangeInput-submit::-moz-focus-inner,
  .ais-RefinementList-showMore::-moz-focus-inner,
  .ais-SearchBox-submit::-moz-focus-inner,
  .ais-SearchBox-reset::-moz-focus-inner,
  .ais-VoiceSearch-button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .ais-ClearRefinements-button[disabled],
  .ais-CurrentRefinements-delete[disabled],
  .ais-CurrentRefinements-reset[disabled],
  .ais-GeoSearch-redo[disabled],
  .ais-GeoSearch-reset[disabled],
  .ais-HierarchicalMenu-showMore[disabled],
  .ais-InfiniteHits-loadPrevious[disabled],
  .ais-InfiniteHits-loadMore[disabled],
  .ais-InfiniteResults-loadMore[disabled],
  .ais-Menu-showMore[disabled],
  .ais-RangeInput-submit[disabled],
  .ais-RefinementList-showMore[disabled],
  .ais-SearchBox-submit[disabled],
  .ais-SearchBox-reset[disabled],
  .ais-VoiceSearch-button[disabled] {
    cursor: default; }

.ais-InfiniteHits-loadPrevious,
.ais-InfiniteHits-loadMore,
.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  overflow-anchor: none; }

.ais-Breadcrumb-list,
.ais-Breadcrumb-item,
.ais-Pagination-list,
.ais-RangeInput-form,
.ais-RatingMenu-link,
.ais-PoweredBy {
  display: flex;
  align-items: center; }

.ais-GeoSearch,
.ais-GeoSearch-map {
  height: 100%; }

.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1em; }

.ais-PoweredBy-logo {
  display: block;
  height: 1.2em;
  width: auto; }

.ais-RatingMenu-starIcon {
  display: block;
  width: 20px;
  height: 20px; }

.ais-SearchBox-input::-ms-clear, .ais-SearchBox-input::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

.ais-SearchBox-input::-webkit-search-decoration, .ais-SearchBox-input::-webkit-search-cancel-button, .ais-SearchBox-input::-webkit-search-results-button, .ais-SearchBox-input::-webkit-search-results-decoration {
  display: none; }

.ais-RangeSlider .rheostat {
  overflow: visible;
  margin-top: 40px;
  margin-bottom: 40px; }

.ais-RangeSlider .rheostat-background {
  height: 6px;
  top: 0px;
  width: 100%; }

.ais-RangeSlider .rheostat-handle {
  margin-left: -12px;
  top: -7px; }

.ais-RangeSlider .rheostat-background {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #aaa; }

.ais-RangeSlider .rheostat-progress {
  position: absolute;
  top: 1px;
  height: 4px;
  background-color: #333; }

.rheostat-handle {
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 50%;
  cursor: grab; }

.rheostat-marker {
  margin-left: -1px;
  position: absolute;
  width: 1px;
  height: 5px;
  background-color: #aaa; }

.rheostat-marker--large {
  height: 9px; }

.rheostat-value {
  margin-left: 50%;
  padding-top: 15px;
  position: absolute;
  text-align: center;
  transform: translateX(-50%); }

.rheostat-tooltip {
  margin-left: 50%;
  position: absolute;
  top: -22px;
  text-align: center;
  transform: translateX(-50%); }

/**
 * Reset
 */
[class^='ais-'] {
  box-sizing: border-box; }

/**
 * Disabled
 */
[class^='ais-'][class$='--disabled'],
[class^='ais-'][class$='--disabled'] *,
.ais-VoiceSearch-button:disabled {
  cursor: not-allowed; }

/**
 * Buttons
 */
.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore,
.ais-ClearRefinements-button,
.ais-InfiniteHits-loadMore,
.ais-RangeInput-submit,
.ais-InfiniteHits-loadPrevious,
.ais-Pagination-link,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-VoiceSearch-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff, #f9fafa);
  border: 1px solid #d1d7d6;
  border-radius: 3px;
  box-shadow: 0 1px 0 0 rgba(84, 92, 91, 0.05);
  color: #454b4a;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2rem;
  justify-content: center;
  line-height: 1.25rem;
  padding: 0 1rem;
  place-items: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset {
  height: 1.5rem; }

.ais-HierarchicalMenu-showMore,
.ais-Menu-showMore,
.ais-RefinementList-showMore {
  margin-top: 1rem; }

.ais-HierarchicalMenu-showMore:hover,
.ais-Menu-showMore:hover,
.ais-RefinementList-showMore:hover,
.ais-ClearRefinements-button:hover,
.ais-InfiniteHits-loadMore:hover,
.ais-RangeInput-submit:hover,
.ais-InfiniteHits-loadPrevious:hover,
.ais-Pagination-item:not(.ais-Pagination-item--selected):not(.ais-Pagination-item--disabled)
.ais-Pagination-link:hover,
.ais-GeoSearch-redo:hover,
.ais-GeoSearch-reset:hover,
.ais-VoiceSearch-button:hover {
  background-image: linear-gradient(-180deg, #fff, #f4f5f5);
  border-color: #d1d7d6; }

.ais-HierarchicalMenu-showMore:focus,
.ais-Menu-showMore:focus,
.ais-RefinementList-showMore:focus,
.ais-ClearRefinements-button:focus,
.ais-InfiniteHits-loadMore:focus,
.ais-RangeInput-submit:focus,
.ais-InfiniteHits-loadPrevious:focus,
.ais-GeoSearch-redo:focus,
.ais-GeoSearch-reset:focus,
.ais-VoiceSearch-button:focus {
  background-image: linear-gradient(-180deg, #fff, #f4f5f5);
  border-color: #8c9a98;
  box-shadow: #8c9a98 0 0 0 1px, rgba(84, 92, 91, 0.05) 0 2px 0 1px;
  outline: currentcolor none medium; }

.ais-HierarchicalMenu-showMore:active,
.ais-Menu-showMore:active,
.ais-RefinementList-showMore:active,
.ais-ClearRefinements-button:active,
.ais-InfiniteHits-loadMore:active,
.ais-InfiniteHits-loadPrevious:active,
.ais-Pagination-item--selected .ais-Pagination-link,
.ais-Pagination-link:active,
.ais-GeoSearch-redo:active,
.ais-GeoSearch-reset:active,
.ais-VoiceSearch-button:active {
  border-color: #d1d7d6;
  box-shadow: rgba(140,154,152, 0.4) 0 1px 4px 0 inset, rgba(140,154,152, 0.4) 0 1px 1px 0 inset, rgba(84, 92, 91, 0.05) 0 1px 0 0; }

.ais-ClearRefinements-button:disabled[disabled],
.ais-InfiniteHits-loadMore:disabled[disabled],
.ais-InfiniteHits-loadPrevious:disabled[disabled],
.ais-Pagination-item--disabled .ais-Pagination-link,
.ais-GeoSearch-redo--disabled,
.ais-GeoSearch-reset--disabled,
.ais-VoiceSearch-button:disabled {
  background-image: linear-gradient(-180deg, #fff, #f4f5f5);
  border-color: #efeff5;
  box-shadow: none;
  color: #e2e6e5; }

.ais-HierarchicalMenu-showMore--disabled[disabled],
.ais-Menu-showMore--disabled[disabled],
.ais-RefinementList-showMore--disabled[disabled] {
  display: none; }

/**
 * Labels
 */
.ais-NumericMenu-label,
.ais-ToggleRefinement-label,
.ais-HierarchicalMenu-item,
.ais-Menu-item,
.ais-RatingMenu-item,
.ais-RefinementList-item {
  display: block;
  font-size: 0.875rem;
  line-height: 1.75rem; }

.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-RatingMenu-link,
.ais-RefinementList-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-wrap: nowrap; }

.ais-Breadcrumb-link,
.ais-HierarchicalMenu-link,
.ais-Menu-link,
.ais-RatingMenu-link {
  color: inherit;
  text-decoration: none; }

.ais-Breadcrumb-link:hover {
  text-decoration: underline; }

.ais-HierarchicalMenu-label,
.ais-Menu-label,
.ais-RefinementList-labelText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ais-HierarchicalMenu-item--selected
> .ais-HierarchicalMenu-link
> .ais-HierarchicalMenu-label,
.ais-Menu-item--selected .ais-Menu-label,
.ais-RefinementList-item--selected,
.ais-RatingMenu-item--selected {
  font-weight: 600; }

/**
 * Count
 */
.ais-ToggleRefinement-count,
.ais-HierarchicalMenu-count,
.ais-Menu-count,
.ais-RatingMenu-count,
.ais-RefinementList-count {
  background: #f4f5f5 none repeat scroll 0% 0%;
  border: 1px solid #e2e6e5;
  border-radius: 99999px;
  color: #454b4a;
  display: inline-flex;
  flex-shrink: 0;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  margin-left: 0.5rem;
  overflow: hidden;
  padding: 0 0.25rem; }

/**
 * No Results
 */
.ais-Menu-noResults,
.ais-HierarchicalMenu-noResults,
.ais-RefinementList-noResults {
  color: #8c9a98;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-top: 1rem; }

/**
 * Selectors
 */
.ais-MenuSelect-select,
.ais-HitsPerPage-select,
.ais-SortBy-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), linear-gradient(-180deg, #fff, #f9fafa);
  background-position: right 1rem center, 0 0;
  background-repeat: no-repeat;
  background-size: 10px, auto;
  border: 1px solid #d1d7d6;
  border-radius: 3px;
  box-shadow: rgba(84, 92, 91, 0.05) 0 1px 0 0;
  color: #454b4a;
  cursor: pointer;
  font: inherit;
  height: 2.5rem;
  outline: currentcolor none medium;
  padding: 0 2.5rem 0 1rem;
  position: relative;
  width: 100%; }

.ais-HitsPerPage-select:-moz-focusring,
.ais-HitsPerPage-select::-moz-focus-inner,
.ais-SortBy-select:-moz-focusring,
.ais-SortBy-select::-moz-focus-inner {
  color: transparent;
  outline: currentcolor none medium; }

.ais-HitsPerPage-select > option:not(:checked),
.ais-SortBy-select > option:not(:checked) {
  color: #454b4a; }

.ais-HitsPerPage-select > option:disabled,
.ais-SortBy-select > option:disabled {
  color: #e2e6e5; }

.ais-HitsPerPage-select:hover,
.ais-SortBy-select:hover {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), linear-gradient(-180deg, #f9fafa, #f4f5f5);
  border-color: #d1d7d6; }

.ais-HitsPerPage-select:focus,
.ais-SortBy-select:focus {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23777aaf" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), linear-gradient(-180deg, #fff, #f4f5f5);
  border-color: #8c9a98;
  box-shadow: #8c9a98 0 0 0 1px, rgba(84, 92, 91, 0.05) 0 2px 0 0; }

.ais-HitsPerPage-select:disabled,
.ais-SortBy-select:disabled {
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="24" xmlns="http://www.w3.org/2000/svg"><g stroke="%23e2e6e5" stroke-width="2" fill="none" fill-rule="evenodd"><path d="M13 7L7 1 1 7M1 17l6 6 6-6"/></g></svg>'), linear-gradient(-180deg, #fff, #f4f5f5);
  border-color: #efeff5;
  box-shadow: none;
  color: #e2e6e5;
  cursor: not-allowed; }

/**
 * Panel
 */
.ais-Panel {
  margin-bottom: 2rem; }

.ais-Panel-header {
  color: #8c9a98;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase; }

.ais-Panel-footer {
  color: #8c9a98;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
  margin-top: 1rem; }

.ais-Panel--collapsible {
  position: relative; }

.ais-Panel--collapsible .ais-Panel-collapseButton {
  background: none;
  border: none;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0; }

.ais-Panel--collapsed .ais-Panel-body,
.ais-Panel--collapsed .ais-Panel-footer {
  display: none; }

/**
 * SearchBox
 */
.ais-SearchBox-form {
  background-color: #fff;
  display: flex;
  font-size: 0.875rem;
  height: 2.5rem;
  line-height: 1.25rem;
  position: relative;
  width: 100%; }

.ais-SearchBox-form::before {
  background: transparent url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%238c9a98%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Ccircle%20cx%3D%2211%22%20cy%3D%2211%22%20r%3D%228%22%3E%3C%2Fcircle%3E%3Cline%20x1%3D%2221%22%20y1%3D%2221%22%20x2%3D%2216.65%22%20y2%3D%2216.65%22%3E%3C%2Fline%3E%3C%2Fsvg%3E") repeat scroll 0% 0%;
  content: '';
  height: 1rem;
  left: 1rem;
  margin-top: -0.5rem;
  position: absolute;
  top: 50%;
  width: 1rem; }

.ais-SearchBox-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d1d7d6;
  border-radius: 3px;
  box-shadow: rgba(140,154,152, 0.3) 0 1px 4px 0 inset;
  caret-color: #8c9a98;
  color: #454b4a;
  flex: 1 1 0%;
  font: inherit;
  max-width: 100%;
  padding-left: 2.5rem; }

.ais-SearchBox-input::-moz-placeholder {
  color: #8c9a98; }

.ais-SearchBox-input::placeholder {
  color: #8c9a98; }

.ais-SearchBox-input:focus {
  border-color: #8c9a98;
  box-shadow: rgba(84, 92, 91, 0.05) 0 1px 0 0;
  outline: currentcolor none medium; }

.ais-SearchBox-input:disabled {
  background: transparent linear-gradient(-180deg, #fff, #f4f5f5) repeat scroll 0% 0%;
  border-color: #efeff5;
  box-shadow: none;
  cursor: not-allowed; }

.ais-SearchBox-input:disabled::-moz-placeholder {
  color: #e2e6e5;
  pointer-events: none; }

.ais-SearchBox-input:disabled::placeholder {
  color: #e2e6e5;
  pointer-events: none; }

.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
  appearance: none; }

.ais-SearchBox-loadingIndicator,
.ais-SearchBox-reset {
  align-items: center;
  border-radius: 50%;
  display: flex;
  fill: #7e8b89;
  height: 20px;
  justify-content: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 20px; }

.ais-SearchBox-reset:focus {
  background: #e2e6e5;
  fill: #fff;
  outline: 0; }

.ais-RefinementList-searchBox .ais-SearchBox-loadingIndicator,
.ais-RefinementList-searchBox .ais-SearchBox-reset {
  right: 0.5rem; }

.ais-SearchBox-loadingIndicator[hidden],
.ais-SearchBox-reset[hidden] {
  display: none; }

.ais-SearchBox-submit {
  display: none; }

.ais-Menu-searchBox,
.ais-RefinementList-searchBox {
  margin-bottom: 0.5rem; }

.ais-Menu-searchBox .ais-SearchBox-form,
.ais-RefinementList-searchBox .ais-SearchBox-form {
  height: 2rem; }

.ais-Menu-searchBox .ais-SearchBox-form::before,
.ais-RefinementList-searchBox .ais-SearchBox-form::before {
  left: 0.5rem; }

.ais-Menu-searchBox .ais-SearchBox-input,
.ais-RefinementList-searchBox .ais-SearchBox-input {
  padding-left: 2rem; }

/**
 * VoiceSearch
 */
.ais-VoiceSearch-button {
  color: #8c9a98;
  height: 48px;
  width: 48px; }

.ais-VoiceSearch-button svg {
  color: currentcolor; }

/**
 * Highlighting and Snippeting
 */
.ais-Highlight-highlighted,
.ais-Snippet-highlighted {
  background-color: rgba(84, 104, 255, 0.1);
  color: #8c9a98;
  font-style: normal; }

/**
 * Hits
 */
.ais-Hits-item,
.ais-InfiniteHits-item {
  align-items: center;
  background: #fff;
  box-shadow: 0 0 0 1px rgba(84, 92, 91, 0.05), 0 1px 3px 0 rgba(84, 92, 91, 0.15);
  display: flex;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
  padding: 1.5rem; }

.ais-Hits-item:first-of-type,
.ais-InfiniteHits-item:first-of-type {
  border-radius: 3px 3px 0 0; }

.ais-Hits-item:last-of-type,
.ais-InfiniteHits-item:last-of-type {
  border-radius: 0 0 3px 3px; }

.ais-Hits-item:only-of-type,
.ais-InfiniteHits-item:only-of-type {
  border-radius: 3px; }

.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadPrevious {
  display: flex;
  margin: 1rem auto; }

/**
 * GeoSearch
 */
.ais-GeoSearch {
  position: relative; }

.ais-GeoSearch-control {
  left: 3.75rem;
  position: absolute;
  top: 1rem; }

.ais-GeoSearch-label {
  align-items: center;
  background-color: #f4f5f5;
  border: 1px solid #e2e6e5;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1.5;
  min-height: 1.5rem;
  padding: 0.25rem 0.5rem; }

.ais-GeoSearch-label,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset {
  white-space: nowrap; }

.ais-GeoSearch-reset {
  bottom: 1.25rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%); }

/**
 * RefinementList
 */
.ais-RefinementList-checkbox,
.ais-GeoSearch-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-position: 50%;
  background-size: 180%;
  border: 1px solid currentcolor;
  border-radius: 3px;
  box-shadow: inset 0 1px 4px 0 rgba(140,154,152, 0.4);
  color: #d1d7d6;
  cursor: inherit;
  height: 1rem;
  margin: 0 0.5rem 0 0;
  min-width: 1rem; }

.ais-RefinementList-item--selected .ais-RefinementList-checkbox,
.ais-GeoSearch-input:checked {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%238c9a98%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2220%206%209%2017%204%2012%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");
  background-size: 14px;
  border-color: currentcolor;
  box-shadow: rgba(84, 92, 91, 0.05) 0 1px 0 0 inset;
  color: #8c9a98; }

.ais-RefinementList-checkbox:focus,
.ais-GeoSearch-input:focus {
  outline: currentcolor none medium; }

.ais-RefinementList-item--selected .ais-RefinementList-checkbox:focus,
.ais-GeoSearch-input:checked:focus {
  box-shadow: rgba(84, 92, 91, 0.05) 0 1px 0 0 inset, currentcolor 0 0 0 1px; }

/**
 * NumericMenu
 */
.ais-NumericMenu-label {
  align-items: center;
  cursor: pointer;
  display: flex; }

.ais-NumericMenu-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  border: 1px solid #d1d7d6;
  border-radius: 50%;
  box-shadow: inset 0 1px 4px 0 rgba(140,154,152, 0.3);
  height: 16px;
  margin: 0 0.5rem 0 0;
  outline: 0;
  position: relative;
  width: 16px; }

.ais-NumericMenu-radio:checked,
.ais-NumericMenu-radio:focus {
  border-color: #8c9a98;
  box-shadow: 0 1px 0 0 rgba(84, 92, 91, 0.05); }

.ais-NumericMenu-radio:focus {
  box-shadow: 0 0 0 1px #8c9a98, 0 1px 0 0 rgba(84, 92, 91, 0.05); }

.ais-NumericMenu-radio:checked:after {
  background: #8c9a98;
  border-radius: 50%;
  bottom: 4px;
  content: '';
  left: 4px;
  position: absolute;
  right: 4px;
  top: 4px; }

/**
 * HierarchicalMenu
 */
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
  margin-left: 1.5rem; }

.ais-HierarchicalMenu-link::before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%23e2e6e5%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%2218%2015%2012%209%206%2015%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  content: '';
  display: inline-block;
  height: 1rem;
  margin-right: 0.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  width: 1rem; }

.ais-HierarchicalMenu-item--selected > .ais-HierarchicalMenu-link::before {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%20fill%3D%22none%22%20stroke%3D%22%238c9a98%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%3Cpolyline%20points%3D%226%209%2012%2015%2018%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E"); }

/**
 * RatingMenu
 */
.ais-RatingMenu-starIcon {
  margin-right: 0.25rem;
  fill: #8c9a98;
  position: relative;
  width: 16px; }

.ais-RatingMenu-item--disabled .ais-RatingMenu-starIcon {
  fill: #e2e6e5; }

.ais-RatingMenu-item--disabled .ais-RatingMenu-count,
.ais-RatingMenu-item--disabled .ais-RatingMenu-label {
  color: #e2e6e5; }

/**
 * ClearRefinements
 */
.ais-ClearRefinements-button {
  width: 100%; }

/**
 * CurrentRefinements
 */
.ais-CurrentRefinements-list {
  display: inline-grid;
  gap: 1rem;
  grid-auto-flow: column; }

.ais-CurrentRefinements-item {
  align-items: center;
  background-color: #f4f5f5;
  border: 1px solid #e2e6e5;
  border-radius: 3px;
  display: flex;
  display: inline-flex;
  font-size: 0.875rem;
  line-height: 1.5;
  min-height: 1.5rem;
  padding: 0.05rem 0.5rem; }

.ais-CurrentRefinements-category {
  display: flex;
  margin-left: 0.5rem; }

.ais-CurrentRefinements-delete {
  color: #e2e6e5;
  height: 100%;
  margin-left: 0.25rem;
  outline-width: 0; }

/**
 * ToggleRefinement
 */
.ais-ToggleRefinement-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between; }

.ais-ToggleRefinement-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(244, 245, 245, 0.08);
  background: #f4f5f5;
  border-radius: 9999px;
  box-shadow: inset 0 1px 4px 0 rgba(140,154,152, 0.4);
  height: 18px;
  pointer-events: none;
  position: relative;
  width: 32px; }

.ais-ToggleRefinement-checkbox:checked {
  background: #8c9a98;
  box-shadow: inset 0 1px 4px 0 rgba(84, 92, 91, 0.1); }

.ais-ToggleRefinement-checkbox:focus {
  outline: 0; }

.ais-ToggleRefinement-checkbox::after {
  background: linear-gradient(-180deg, #fff, #f4f5f5);
  border-radius: 9999px;
  box-shadow: 1px 1px 1px 0 rgba(84, 92, 91, 0.05);
  content: '';
  height: 16px;
  left: 1px;
  position: absolute;
  top: 1px;
  transition: all 100ms ease-in-out;
  width: 16px; }

.ais-ToggleRefinement-checkbox:checked::after {
  transform: translateX(14px); }

/**
 * RangeInput
 */
.ais-RangeInput-input {
  align-items: center;
  background-color: #fff;
  border: 1px solid #d1d7d6;
  border-radius: 3px;
  box-shadow: inset 0 1px 4px 0 rgba(140,154,152, 0.3);
  caret-color: #8c9a98;
  color: #454b4a;
  cursor: text;
  display: flex;
  flex: 1;
  font-size: 0.875rem;
  height: 2rem;
  line-height: 1.25rem;
  min-width: 0;
  overflow: hidden;
  padding: 0 1rem;
  position: relative; }

.ais-RangeInput-input:focus {
  border-color: #8c9a98;
  box-shadow: 0 1px 0 0 rgba(84, 92, 91, 0.05);
  outline: 0; }

.ais-RangeInput-separator {
  margin: 0 0.5rem; }

.ais-RangeInput-submit {
  margin-left: 0.5rem; }

/**
 * RangeSlider
 */
.ais-RangeSlider .rheostat {
  margin: 40px 6px; }

.ais-RangeSlider .rheostat-horizontal {
  cursor: pointer;
  width: calc(100% - 15px); }

.ais-RangeSlider .rheostat-background {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 0.5px 0 0 rgba(255, 255, 255, 0.05);
  height: 4px; }

.ais-RangeSlider .rheostat-progress {
  background-color: #8c9a98;
  border-radius: 3px;
  height: 4px;
  max-width: 100%;
  top: 0; }

.ais-RangeSlider .rheostat-tooltip {
  font-weight: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.ais-RangeSlider .rheostat-handle {
  background-color: #fff;
  background-image: linear-gradient(-180deg, #fff, #f9fafa);
  border: 1px solid #d1d7d6;
  border-radius: 2px;
  box-shadow: 0 1px 0 0 rgba(84, 92, 91, 0.05);
  height: 24px;
  margin-left: -6px;
  top: -11px;
  width: 12px; }

.ais-RangeSlider .rheostat-handle::before,
.ais-RangeSlider .rheostat-handle::after {
  background-color: #d1d7d6;
  content: '';
  height: 12px;
  position: absolute;
  top: 6px;
  width: 1px; }

.ais-RangeSlider .rheostat-handle::before {
  left: 4px; }

.ais-RangeSlider .rheostat-handle::after {
  right: 4px; }

.ais-RangeSlider .rheostat-marker {
  background-color: #d1d7d6; }

/**
 * Pagination
 */
.ais-Pagination-item:first-child > .ais-Pagination-link {
  border-radius: 3px 0 0 3px; }

.ais-Pagination-item + .ais-Pagination-item > .ais-Pagination-link {
  border-radius: 0;
  margin-left: -1px; }

.ais-Pagination-item:last-child > .ais-Pagination-link {
  border-radius: 0 3px 3px 0; }

.ais-Pagination-item {
  display: inline-block; }

.ais-Pagination-item--selected .ais-Pagination-link {
  font-weight: bold; }

/**
 * Breadrumb
 */
.ais-Breadcrumb-link,
.ais-Breadcrumb-separator {
  color: #8c9a98; }

.ais-Breadcrumb-separator {
  display: flex;
  margin: 0 0.5rem; }

.ais-Breadcrumb-item--selected {
  font-weight: 600; }

.ais-Breadcrumb-item--selected .ais-Breadcrumb-separator {
  font-weight: normal; }

@media (max-width: 767px) {
  .ais-SearchBox-input,
  .ais-RangeInput-input {
    font-size: 1rem; } }
/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

