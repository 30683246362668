.react-autosuggest__container {
  width: 100%;
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  padding: 8px 10px 8px 35px !important;
  border-radius: 4px !important;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  outline: none;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  background-color: #ffffff;
  z-index: 100;
  box-shadow: 0px 4px 10px 0px #00000033;

  @apply fixed max-2xl:left-0 max-2xl:bottom-0 max-2xl:w-screen max-2xl:top-0 mt-[81px] overflow-y-auto rounded-sm font-helvetica 2xl:absolute 2xl:right-0 2xl:mt-2 2xl:w-full;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @apply divide-y divide-black-100;
}

.react-autosuggest__suggestion {
  @apply px-3.5 py-2.5 cursor-pointer
}

.react-autosuggest__suggestion--highlighted {
  @apply bg-blue-50;
}

.react-autosuggest__section-container {
  @apply border-t border-dashed border-blue-400;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  @apply py-3 px-2.5 bg-blue-50 text-blue-700 font-bold text-sm;
}

.react-autosuggest__section-container--first .react-autosuggest__section-title:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
